import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import H1 from "~/components/Typography/H1";
import LessonLayout from "~/layouts/lesson";
import ContentWrapper from "~/components/content-wrapper";
import UnorderedList from "~/components/List/Unordered";

const Page = () => {
  return (
    <LessonLayout
      current_step={10}
      lesson={"Lesson 4"}
      color={"ms"}
      total_count={15}
      module_title={undefined}
    >
      <ContentWrapper>
        <H1 underline>Step 10</H1>

        <p>What can people do if they have concerns about an OTC medication or a dietary supplement? They can contact the FDA or another agency, the Federal Trade Commission (FTC).</p>
        <p>The FTC can file complaints or charges against marketing companies that make invalid claims about products.</p>

        <div className="mt-5 w-2/3 flex content-end">
          <StaticImage
            alt="Hand using tablet."
            className="rounded"
            src="../../../images/lesson_4_step_10.jpg"
            loading="eager"
            placeholder="blurred"
            style={{
              filter: "drop-shadow(8px 8px 8px rgba(0, 0, 0, 0.25))"
            }}
          />
        </div>

        <p>The FTC has rules for ads for these products. They include the following:</p>

        <UnorderedList>
          <li>Ads must be truthful.</li>
          <li>Ads must have evidence to back up their claims.</li>
        </UnorderedList>

        <p>The amount of evidence can depend on the claim made. At a minimum, the company must have the evidence it claims to have. For example, a company cannot say that "2 out of 3 doctors recommend ABC pain reliever" if they have not done a reliable survey to get this result. In other cases, companies should have scientific tests or studies that have been reviewed by appropriate experts.</p>
      </ContentWrapper>
    </LessonLayout>
  )
}

export default Page;

